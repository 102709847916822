/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  //padding-top: 20px;
  //padding-bottom: 15px;
  width: 100%;
  background-color: #3e454d;
  color: #cccccc;
  // border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  // a {
  //   color: #cccccc;
  // }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #copyright {
    border-top: 1px dotted #777;
  }
  .dropdown-list {
    color: #333333;
  }
}

/* --------- asc styles --------- */
#header {
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 160px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-nav-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-nav-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
      color: $theme-primary;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: $theme-primary;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  #state-login {
    max-width: 170px;
    margin-top: 15px;
    clear: right;
    z-index: 100;
    .dropdown-list {
      width: 210px;
      font-size: 0.9rem;
      span.dropdown {
        height: 32px;
        line-height: 30px;
      }
      .drop {
        z-index: 100;
        top: 32px;
      }
    }
    .cta-btn {
      min-width: 100%;
      height: 32px;
      line-height: 0.9;
      font-size: 16px;
      font-family: inherit;
      font-weight: 600;
      width: 150px;
    }
  }
}
.text-shadow {
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
}
.text-shadow-sm {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}
.text-red {
  color: #db151c;
}
.text-green {
  color: #90bf3b;
}
.text-blue {
  color: #59adf8;
}
.text-orange {
  color: #f80;
}
.border-blue {
  border-color: #a2d3ff !important;
}
#cta-wrap {
  background-color: #61b1f8;
  h2 {
    color: #fff;
  }
}
.dropdown-list.dropdown-cta {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 300px;
  span.dropdown {
    padding: 15px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background-color: $cta-orange;
    border: 0;
    width: 100%;
    height: auto;
    line-height: 1;
    font-size: 22px;
    text-align: left;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    transition: background-image 0.1s ease-in-out 0.05s;
    + .drop {
      top: 50px;
    }
    &:hover,
    &.is-active {
      background: darken($cta-orange, 5);
    }
  }
}
#header-wrap {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
#banner-wrap {
  h1,
  p {
    letter-spacing: 4px;
    text-shadow:
      5px 5px 15px #fff,
      5px -5px 15px #fff,
      -5px -5px 15px #fff,
      -5px 5px 15px #fff;
  }
  hr {
    margin: 0.5rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}
#headline-wrap {
  background: url(../images/breadcrumb.jpg) no-repeat 0 50% / cover;
}
.bg-theme {
  background-color: #61b1f8;
}
#points-wrap {
  .approved {
    .icon-box {
      background-position: 0 0;
      &:hover {
        background-position: -100px 0px;
      }
    }
  }
  .safety {
    .icon-box {
      background-position: 0 -105px;
      &:hover {
        background-position: -100px -105px;
      }
    }
  }
  .access {
    .icon-box {
      background-position: 0 -210px;
      &:hover {
        background-position: -100px -210px;
      }
    }
  }
  .support {
    .icon-box {
      background-position: 0 -315px;
      &:hover {
        background-position: -100px -315px;
      }
    }
  }
  .icon-box {
    width: 100px;
    height: 105px;
    background: url(../images/product-box-bg-sm.png) no-repeat;
    margin: 0 auto 10px;
  }
  .text {
    font-size: 0.88rem;
  }
}
#states-wrap {
  background: url(../images/choosestatebg-mobile.jpg) no-repeat 50% 0 / cover;
  .icon-box {
    width: 100px;
    height: 100px;
    transition: none;
    margin: 0 auto 10px auto;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
    border: 1px solid #fff;
    .inner-icon {
      background: #fff url(../images/i-state.png) no-repeat;
      width: 100%;
      height: 100%;
      transition: none;
      padding: 5px;
      border-radius: 50%;
      text-align: center;
    }
  }
  .california {
    .inner-icon {
      background-position: 0 6px;
    }
  }
  .florida {
    .inner-icon {
      background-position: 0 -147px;
    }
  }
  .indiana {
    .inner-icon {
      background-position: 0 -320px;
    }
  }
  .texas {
    .inner-icon {
      background-position: 0 -711px;
    }
  }
  .otherstates {
    .inner-icon {
      background-position: -1px -790px;
    }
  }
}
#states-wrap {
  .circle {
    .icon {
      background: url(../images/choose_teen.png) no-repeat 0 0;
      height: 45px;
      width: 62px;
      margin: 5px auto 15px auto;
      display: block;
      &.fun {
        background: url(../images/choose_teen.png) no-repeat 0 -45px;
      }
      &.prices {
        background: url(../images/choose_teen.png) no-repeat 1px -90px;
      }
      &.support {
        background: url(../images/choose_teen.png) no-repeat 0 -139px;
      }
    }
    .text {
      text-transform: uppercase;
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 1.3;
      display: inline-block;
    }
  }
}
.green-box {
  background: #90bf3b;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    max-width: 220px;
  }
  #banner-wrap {
    background: transparent url('/images/color-splat-bg.jpg') no-repeat 50% 50% / cover;
    .detail-box {
      padding: 1.5rem;
      background-color: rgba(255, 255, 255, 0.75);
      border-radius: 3px;
    }
  }
  #states-wrap {
    .circle {
      display: block;
      margin: 0 auto;
      width: 175px;
      height: 175px;
      padding: 5px;
      border-radius: 50%;
      background: transparent;
      border: 1px solid #fff;
      .inner-circle {
        display: block;
        padding: 25px 30px 5px 30px;
        border-radius: 50%;
        background: #fff;
        width: 100%;
        height: 100%;
      }
      .icon {
        background: url(../images/choose_teen.png) no-repeat 0 0;
        height: 45px;
        width: 62px;
        margin: 5px auto 15px auto;
        display: block;
        &.fun {
          background: url(../images/choose_teen.png) no-repeat 0 -45px;
        }
        &.prices {
          background: url(../images/choose_teen.png) no-repeat 0 -90px;
        }
        &.support {
          background: url(../images/choose_teen.png) no-repeat 0 -139px;
        }
      }
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #header-wrap {
    border-top: 3px solid $theme-nav-primary;
  }
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header-wrap {
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #headline-wrap {
    &.money #headline {
      background: url(../images/bcrmb_imgs.png) 95% -745px no-repeat;
    }
    &.contact #headline {
      background: url(../images/bcrmb_imgs.png) 95% -254px no-repeat;
    }
    &.chart #headline {
      background: url(../images/bcrmb_imgs.png) 95% -20px no-repeat;
    }
    &.faqs #headline {
      background: url(../images/bcrmb_imgs.png) 95% -1435px no-repeat;
    }
    &.map #headline {
      background: url(../images/bcrmb_imgs.png) 95% -490px no-repeat;
    }
    &.friends #headline {
      background: url(../images/bcrmb_imgs.png) 95% -965px no-repeat;
    }
    &.approved #headline {
      background: url(../images/bcrmb_imgs.png) 95% -1190px no-repeat;
    }
    &.teen #headline {
      background: url(../images/bcrmb_imgs.png) 95% -1668px no-repeat;
    }
  }
  .sidebar {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  #points-wrap {
    .approved .icon-box:hover {
      background-position-x: -200px;
    }
    .safety .icon-box {
      background-position: 0 -210px;
      &:hover {
        background-position: -200px -210px;
      }
    }
    .access .icon-box {
      background-position: 0 -420px;
      &:hover {
        background-position: -200px -420px;
      }
    }
    .support .icon-box {
      background-position: 0 -630px;
      &:hover {
        background-position: -200px -630px;
      }
    }
    .icon-box {
      width: 200px;
      height: 210px;
      background: url(../images/product-box-bg.png) no-repeat;
    }
  }
  #states-wrap {
    background: url(../images/choosestatebg1.jpg) no-repeat 50% 0 / cover;
    #states {
      background: url(../images/groupimg.png) no-repeat center bottom;
      padding-bottom: 370px;
      overflow: hidden;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #state-login {
    min-width: 330px;
    .cta-btn {
      min-width: 150px !important;
    }
  }
  #banner-wrap {
    #banner {
      min-height: 300px;
      background: transparent url('/images/slide1bg.png') no-repeat 50% 0 / cover;
    }
    .detail-box {
      margin-bottom: 25px;
      width: 50%;
      padding: 0;
      background-color: transparent;
      hr {
        width: 90%;
        margin: 0.5rem auto;
      }
      h1 {
        line-height: 1.2;
      }
    }
    &.california {
      #banner {
        background: transparent url('/images/slide1bg_ca.png') no-repeat 50% 0 / cover;
      }
    }
    &.florida {
      #banner {
        background: transparent url('/images/slide1bg_fl.png') no-repeat 50% 0 / cover;
      }
    }
    &.indiana {
      #banner {
        background: transparent url('/images/slide1bg_in.png') no-repeat 50% 0 / cover;
      }
    }
    &.texas {
      #banner {
        background: transparent url('/images/slide1bg_tx.png') no-repeat 50% 0 / cover;
      }
    }
  }
}
